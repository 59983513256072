import { Component } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { Credentials } from "../../../entities/credentials";
import { MatIconButton, MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-credentials-input",
	templateUrl: "./credentials-input.component.html",
	styleUrls: ["./credentials-input.component.scss"],
	imports: [
		MatDialogContent,
		ReactiveFormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatIcon,
		MatPrefix,
		MatIconButton,
		MatSuffix,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgxTolgeeModule,
	],
})
export class CredentialsInputComponent {
	formGroup: UntypedFormGroup;
	hide = true;

	constructor(
		formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<CredentialsInputComponent>
	) {
		this.formGroup = formBuilder.group({
			username: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required]],
		});
	}

	generate(): void {
		const username: string = this.formGroup.get("username").value;
		const password: string = this.formGroup.get("password").value;
		const result: Credentials = { username: username.trim(), password: password.trim() };
		this.dialogRef.close(result);
	}
}
