import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Department } from "../../../interfaces/department";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { OrderByPipe } from "ngx-pipes";
import { CreateDepartmentComponent } from "../create-department/create-department.component";
import { MatButtonModule } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { DepartmentService } from "../../../services/department.service";

@Component({
	selector: "app-departments",
	templateUrl: "./departments.component.html",
	styleUrls: ["./departments.component.scss"],
	imports: [
		MatDialogModule,
		MatTableModule,
		MatButtonModule,
		MatSort,
		MatPaginator,
		NgIf,
		MatProgressSpinner,
		MatIconModule,
		NgxTolgeeModule,
	],
})
export class DepartmentsComponent implements OnInit {
	isLoading: boolean = false;
	displayedColumns: string[] = ["label"];
	dataSource = new MatTableDataSource<Department>();
	departments_all: Department[] = [];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private departmentService: DepartmentService,
		private dialog: MatDialog,
		private oPipe: OrderByPipe
	) {}

	ngOnInit(): void {
		this.getDepartments();
	}

	getDepartments() {
		this.isLoading = true;
		this.departmentService.getDepartments().subscribe((departments) => {
			this.departments_all = this.oPipe.transform(departments, "label");
			this.dataSource.data = this.departments_all;
			this.isLoading = false;

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	createDepartment() {
		this.dialog
			.open(CreateDepartmentComponent, {
				minWidth: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((department) => {
				if (department) {
					this.departments_all.push(department);
					this.dataSource.data = this.oPipe.transform(this.departments_all, "label");
				}
			});
	}
}
