import { Component, OnInit, ViewChild } from "@angular/core";
import {
	MatCell,
	MatCellDef,
	MatColumnDef,
	MatHeaderCell,
	MatHeaderCellDef,
	MatHeaderRow,
	MatHeaderRowDef,
	MatRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Job } from "../../../interfaces/job";
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent } from "@angular/material/dialog";
import { OrderByPipe } from "ngx-pipes";
import { CreateJobComponent } from "../create-job/create-job.component";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { JobService } from "../../../services/job.service";

@Component({
	selector: "app-jobs",
	templateUrl: "./jobs.component.html",
	styleUrls: ["./jobs.component.scss"],
	imports: [
		MatDialogContent,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatCellDef,
		MatCell,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatPaginator,
		MatDialogActions,
		MatButton,
		MatIcon,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class JobsComponent implements OnInit {
	isLoading: boolean = false;
	displayedColumns: string[] = ["label"];
	dataSource = new MatTableDataSource<Job>();
	jobs_all: Job[] = [];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private jobService: JobService,
		private dialog: MatDialog,
		private oPipe: OrderByPipe
	) {}

	ngOnInit(): void {
		this.getJobs();
	}

	getJobs() {
		this.isLoading = true;
		this.jobService.getJobs().subscribe((jobs) => {
			this.jobs_all = this.oPipe.transform(jobs, "label");
			this.dataSource.data = this.jobs_all;
			this.isLoading = false;

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	createJob() {
		this.dialog
			.open(CreateJobComponent, { minWidth: "600px", disableClose: true })
			.afterClosed()
			.subscribe((job) => {
				if (job) {
					this.jobs_all.push(job);
					this.dataSource.data = this.oPipe.transform(this.jobs_all, "label");
				}
			});
	}
}
