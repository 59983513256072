import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { JobService } from "../../../services/job.service";

@Component({
	selector: "app-create-job",
	templateUrl: "./create-job.component.html",
	styleUrls: ["./create-job.component.scss"],
	imports: [
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatIcon,
		MatSuffix,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class CreateJobComponent {
	label: string = "";
	isLoading: boolean = false;

	constructor(
		private jobService: JobService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<CreateJobComponent>,
		private translate: TranslateService
	) {}

	apply() {
		this.isLoading = true;
		this.jobService.createJob(this.label).subscribe({
			next: (job) => {
				this.toastr.success(this.translate.instant("job.create.success"));
				this.dialogRef.close(job);
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}
}
