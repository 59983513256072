import { Component, OnInit, ViewChild } from "@angular/core";
import {
	MatCell,
	MatCellDef,
	MatColumnDef,
	MatHeaderCell,
	MatHeaderCellDef,
	MatHeaderRow,
	MatHeaderRowDef,
	MatRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { User } from "../../../interfaces/users/user";
import { OrderByPipe, UniquePipe } from "ngx-pipes";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { MatDialog } from "@angular/material/dialog";
import { AddUserComponent } from "../add-user/add-user.component";
import { first, Observable, of } from "rxjs";
import * as fs from "file-saver";
import { ToastrService } from "ngx-toastr";
import { UserRolesComponent } from "../user-roles/user-roles.component";
import { TransferManagersComponent } from "../transfer-managers/transfer-managers.component";
import { DepartmentsComponent } from "../../departments/departments/departments.component";
import { JobsComponent } from "../../jobs/jobs/jobs.component";
import * as fuzz from "fuzzball";
import { ERoles } from "../../../enums/eroles";
import { QrCodeService } from "../../../services/qr-code.service";
import { ConnectToWifiComponent } from "../../devices/connect-to-wifi/connect-to-wifi.component";
import { CredentialsInputComponent } from "../credentials-input/credentials-input.component";
import { UserService } from "../../../services/user.service";
import { CreateMultipleUsersComponent } from "../create-multiple-users/create-multiple-users.component";
import { EFilterMode } from "../../institutes/institute-filter/efilter-mode";
import { CreateAuthCodeComponent } from "../../auth/create-auth-code/create-auth-code.component";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatChipListbox, MatChipOption } from "@angular/material/chips";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatCheckbox } from "@angular/material/checkbox";
import { InstituteFilterComponent } from "../../institutes/institute-filter/institute-filter.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatButton, MatFabButton } from "@angular/material/button";
import { MatCard, MatCardContent, MatCardTitle } from "@angular/material/card";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { TransferUserComponent } from "../transfer-user/transfer-user.component";
import { NamePipe } from "../../../pipes/name.pipe";
import { SubscriptionService } from "../../../services/subscription.service";

declare const ExcelJS: any;

@Component({
	selector: "app-users",
	templateUrl: "./users.component.html",
	styleUrls: ["./users.component.scss"],
	imports: [
		MatIcon,
		NgIf,
		MatCard,
		MatCardTitle,
		MatCardContent,
		MatButton,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		FormsModule,
		MatSuffix,
		InstituteFilterComponent,
		MatCheckbox,
		MatRadioGroup,
		MatRadioButton,
		MatFabButton,
		NgFor,
		MatTable,
		MatSort,
		MatColumnDef,
		MatHeaderCellDef,
		MatHeaderCell,
		MatSortHeader,
		MatCellDef,
		MatCell,
		MatChipListbox,
		MatChipOption,
		MatHeaderRowDef,
		MatHeaderRow,
		MatRowDef,
		MatRow,
		MatMenuTrigger,
		MatMenu,
		MatMenuItem,
		MatPaginator,
		AsyncPipe,
		NgxTolgeeModule,
		NamePipe,
	],
})
export class UsersComponent implements OnInit {
	displayedColumns: string[] = ["fullname", "job", "institute", "roles"];
	dataSource = new MatTableDataSource<User>();
	users_all: User[] = [];
	statistics: any = [];
	filters = {
		enabled: true,
		keyword: "",
		institutes: [],
		manager: false,
		admin_directory: false,
		admin_users: false,
		roles: -1,
		status: {
			enabled: true,
			disabled: false,
			pending: false,
			deleted: false,
		},
	};
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild("auto") matAutocomplete: MatAutocomplete;

	constructor(
		private subscriptionService: SubscriptionService,
		public manager: MitpManagerService,
		private dialog: MatDialog,
		private toastr: ToastrService,
		private oPipe: OrderByPipe,
		private uPipe: UniquePipe,
		private qrCodeService: QrCodeService,
		private translate: TranslateService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.clearFilters();
		this.userService.getUsers().subscribe((users) => {
			this.users_all = users;
			this.filterUsers();
			this.calculateStatistics();

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	calculateStatistics() {
		this.statistics = [];
		const arr = this.uPipe.transform(this.users_all, "id");
		this.statistics.push({
			key: "Nombre d'utilisateurs",
			value: arr.length,
		});
		if (arr.length == 0) {
			this.statistics.push({ key: "Comptes activés", value: `0` });
			this.statistics.push({ key: "Comptes en attente", value: `0` });
			this.statistics.push({ key: "Comptes désactivés", value: `0` });
			this.statistics.push({ key: "Comptes supprimés", value: `0` });
			this.statistics.push({
				key: "Gestionnaire Protocoles",
				value: `0`,
			});
			this.statistics.push({
				key: "Gestionnaire Utilisateurs",
				value: `0`,
			});
			this.statistics.push({ key: "Gestionnaire Annuaire", value: `0` });
		} else {
			let result = this.users_all.filter((user) => user.status == 1);
			this.statistics.push({
				key: "Comptes activés",
				value: `${result.length}`,
			});

			result = this.users_all.filter((user) => user.status == 2);
			this.statistics.push({
				key: "Comptes en attente",
				value: `${result.length}`,
			});

			result = this.users_all.filter((user) => user.status == 0);
			this.statistics.push({
				key: "Comptes désactivés",
				value: `${result.length}`,
			});

			result = this.users_all.filter((user) => user.status == -1);
			this.statistics.push({
				key: "Comptes supprimés",
				value: `${result.length}`,
			});

			result = this.users_all.filter((user) => user.roles.includes("ROLE_MANAGER"));
			this.statistics.push({
				key: "Gestionnaire Protocoles",
				value: `${result.length}`,
			});

			const validation = (role) =>
				role === "ROLE_SUPREME_ADMIN" ||
				role === "ROLE_ADMIN_USERS_FULL" ||
				role.startsWith("ROLE_ADMIN_USERS_DEPARTMENT") ||
				role.startsWith("ROLE_ADMIN_USERS_INSTITUTE");
			result = this.users_all.filter((user) => user.roles.some(validation));
			this.statistics.push({
				key: "Gestionnaire Utilisateurs",
				value: `${result.length}`,
			});

			result = this.users_all.filter((user) => user.roles.includes("ROLE_ADMIN_DIRECTORY"));
			this.statistics.push({
				key: "Gestionnaire Annuaire",
				value: `${result.length}`,
			});
		}
	}

	clearFilters() {
		this.filters = {
			enabled: true,
			keyword: "",
			institutes: [],
			manager: false,
			admin_directory: false,
			admin_users: false,
			roles: -1,
			status: {
				enabled: true,
				disabled: false,
				pending: false,
				deleted: false,
			},
		};
		this.filterUsers();
	}

	filterUsers() {
		let users = this.users_all;
		let tmp = [];
		if (this.filters.keyword != "") {
			const filterValue = this.filters.keyword.trim().toLowerCase();
			users.forEach((user) => {
				if (
					fuzz.partial_ratio(`${user.firstname} ${user.lastname}`.toLowerCase(), filterValue) > 90 ||
					fuzz.partial_ratio(`${user.lastname} ${user.firstname}`.toLowerCase(), filterValue) > 90
				) {
					tmp.push(user);
				}
			});
			users = tmp;
			tmp = [];
		}
		if (this.filters.institutes.length > 0) {
			users = users.filter((user) =>
				this.filters.institutes.map((institute) => institute.id).includes(user.institute.id)
			);
		}
		if (this.filters.status.enabled) {
			tmp = tmp.concat(users.filter((user) => user.status == 1));
		}
		if (this.filters.status.pending) {
			tmp = tmp.concat(users.filter((user) => user.status == 2));
		}
		if (this.filters.status.deleted) {
			tmp = tmp.concat(users.filter((user) => user.status == -1));
		}
		if (this.filters.status.disabled) {
			tmp = tmp.concat(users.filter((user) => user.status == 0));
		}
		users = this.uPipe.transform(tmp, "id");
		tmp = [];
		if (this.filters.roles == 1) {
			if (this.filters.manager) {
				tmp = tmp.concat(users.filter((user) => user.roles.includes("ROLE_MANAGER")));
			}
			if (this.filters.admin_directory) {
				tmp = tmp.concat(users.filter((user) => user.roles.includes("ROLE_ADMIN_DIRECTORY")));
			}
			if (this.filters.admin_users) {
				const validation = (role) =>
					role === "ROLE_SUPREME_ADMIN" ||
					role === "ROLE_ADMIN_USERS_FULL" ||
					role.startsWith("ROLE_ADMIN_USERS_DEPARTMENT") ||
					role.startsWith("ROLE_ADMIN_USERS_INSTITUTE");
				tmp = tmp.concat(users.filter((user) => user.roles.some(validation)));
			}
		} else {
			tmp = users;
		}
		tmp = this.uPipe.transform(tmp, "id");
		this.dataSource.data = this.oPipe.transform(tmp, ["lastname", "firstname"]);
	}

	filteredRoles(user: User): Observable<string[]> {
		let result = [];
		user.roles.forEach((role) => {
			if (role == ERoles.ROLE_MANAGER) {
				result.push("Protocoles");
			}
			if (role == ERoles.ROLE_ADMIN_DIRECTORY) {
				result.push("Annuaire");
			}
			if (role.startsWith("ROLE_ADMIN_USERS_INSTITUTE_")) {
				result.push("Utilisateurs NE");
			}
			if (role.startsWith("ROLE_ADMIN_USERS_DEPARTMENT_")) {
				result.push("Utilisateurs ND");
			}
			if (role == ERoles.ROLE_QUALITY) {
				result.push("Qualité");
			}
			if (role == ERoles.ROLE_PHARMACY) {
				result.push("Pharmacie");
			}
			if (role == ERoles.ROLE_CODER) {
				result.push("Codeur");
			}
		});
		result = this.uPipe.transform(result);
		return of(result);
	}

	createUser() {
		this.dialog
			.open(AddUserComponent, {
				data: null,
				disableClose: true,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((user) => {
				this.users_all.push(user);
				this.filterUsers();
			});
	}

	createMultipleUsers(): void {
		this.dialog.open(CreateMultipleUsersComponent, {
			disableClose: true,
			minWidth: "600px",
		});
	}

	editUser(user: User) {
		this.dialog
			.open(AddUserComponent, {
				data: user,
				disableClose: true,
				minWidth: "600px",
			})
			.afterClosed()
			.subscribe((newUser) => {
				if (newUser) {
					user = newUser;
				}
			});
	}

	editRoles(user: User) {
		this.dialog
			.open(UserRolesComponent, {
				data: user,
				minWidth: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe(() => {
				this.filterUsers();
			});
	}

	deleteUser(user: User) {
		this.manager
			.showConfirmDialog(`Êtes-vous sûr de vouloir supprimer ${user.firstname} ${user.lastname} ?`, "Supprimer")
			.afterClosed()
			.subscribe((answer) => {
				if (answer) {
					this.userService.deleteUser(user.id).subscribe((response) => {
						if (response.status == 204) {
							this.toastr.success("L'utilisateur a été supprimé avec succès.");
							user.status = -1;
							this.filterUsers();
							this.calculateStatistics();
						} else {
							this.toastr.error("Une erreur inconnue est survenue !");
						}
					});
				} else {
					this.toastr.info("La suppression de l'utilisateur a été annulée !");
				}
			});
	}

	disableUser(user: User): void {
		this.manager
			.showConfirmDialog(
				`Êtes-vous sûr de vouloir désactiver le compte de ${user.firstname} ${user.lastname} ?`,
				"Désactiver"
			)
			.afterClosed()
			.subscribe((answer) => {
				if (answer) {
					this.userService.updateUserStatus(user.id, "disable").subscribe((response) => {
						if (response.status == 204) {
							this.toastr.success("Compte désactivé avec succès.");
							user.status = 0;
							this.filterUsers();
							this.calculateStatistics();
						} else {
							this.toastr.error("Une erreur inconnue est survenue !");
						}
					});
				} else {
					this.toastr.info("La désactivation de l'utilisateur a été annulée !");
				}
			});
	}

	renewPassword(user: User, type: string) {
		switch (type) {
			case "link":
				this.resetPasswordByLink(user);
				return;
			case "password":
				this.resetPasswordByPassword(user);
				return;
		}
	}

	exportExcel() {
		const workbook = new ExcelJS.Workbook();
		workbook.created = new Date();
		workbook.modified = new Date();
		const worksheet = workbook.addWorksheet("Utilisateurs");
		worksheet.properties.defaultColWidth = 30;
		const header = ["Etablissement", "Nom", "Prénom", "Rôle", "Adresse mail", "RPPS", "Etat"];
		const headerRow = worksheet.addRow(header);
		headerRow.fill = {
			type: "pattern",
			pattern: "solid",
			fgColor: { argb: "3498DB" },
		};
		headerRow.font = {
			bold: true,
			color: { argb: "FFFFFF" },
		};
		headerRow.alignment = { horizontal: "center" };
		const userStatus = {
			"1": "Activé",
			"2": "En attente",
			"0": "Désactivé",
			"-1": "Supprimé",
		};
		this.users_all.forEach((user) => {
			console.log(user);
			const data = [
				user.institute.label,
				user.lastname,
				user.firstname,
				user.job.label,
				user.email,
				user.rpps,
				userStatus[user.status],
			];
			const row = worksheet.addRow(data);
			row.alignment = { wrapText: true };
		});
		workbook.xlsx.writeBuffer().then((xls64) => {
			const data = new Blob([xls64], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			fs.saveAs(data, "Utilisateurs.xlsx");
		});
	}

	transferManagers() {
		this.dialog.open(TransferManagersComponent, {
			minWidth: "600px",
			disableClose: true,
		});
	}

	roles() {
		this.dialog.open(JobsComponent, {
			minWidth: "600px",
			disableClose: true,
		});
	}

	departments() {
		this.dialog.open(DepartmentsComponent, {
			minWidth: "600px",
			disableClose: true,
		});
	}

	notifyExpiredSubscription(user: User) {
		this.subscriptionService.notifyExpiredSubscription(user.id).subscribe(() => {
			this.toastr.success("Notification envoyée avec succès.");
		});
	}

	private resetPasswordByLink(user: User) {
		this.manager
			.showConfirmDialog(
				`Voulez-vous renouveler le mot de passe par lien pour ${user.firstname} ${user.lastname} ?`,
				"Renouveler"
			)
			.afterClosed()
			.subscribe((answer) => {
				if (answer) {
					this.userService.resetPassword(user.id, "link").subscribe((response) => {
						if (response.status == 204) {
							this.toastr.success(
								"Un lien pour définir un nouveau mot de passe a été envoyé avec succès."
							);
						} else {
							this.toastr.error("Une erreur inconnue est survenue !");
						}
					});
				} else {
					this.toastr.info("Le renouvellement du mot de passe a été annulé.");
				}
			});
	}

	private resetPasswordByPassword(user: User) {
		this.manager
			.showConfirmDialog(
				`Voulez-vous générer un nouveau mot de passe pour ${user.firstname} ${user.lastname} ?`,
				"Renouveler"
			)
			.afterClosed()
			.subscribe((answer) => {
				if (answer) {
					this.userService.resetPassword(user.id, "password").subscribe((response) => {
						if (response.status == 204) {
							this.toastr.success("Un nouveau mot de passe a été envoyé avec succès.");
						} else {
							this.toastr.error("Une erreur inconnue est survenue !");
						}
					});
				} else {
					this.toastr.info("Le renouvellement du mot de passe a été annulé.");
				}
			});
	}

	showAuthCode(): void {
		this.dialog.open(CreateAuthCodeComponent, {
			minWidth: "600px",
			maxHeight: "900px",
			disableClose: true,
			autoFocus: false,
		});
	}

	openWifiGenerator(): void {
		this.dialog.open(ConnectToWifiComponent, {
			width: "400px",
			disableClose: true,
		});
	}

	openCredentialsQrGenerator(): void {
		this.dialog
			.open(CredentialsInputComponent, {
				disableClose: true,
				width: "600px",
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.qrCodeService.generateCredentialsQrCode(result);
				} else {
					this.toastr.info(this.translate.instant("core.message.nomodification"));
				}
			});
	}

	disableMFA(user: User): void {
		this.manager
			.showConfirmDialog(`Voulez-vous désactiver la 2FA pour ${user.firstname} ${user.lastname} ?`, "Désactiver")
			.afterClosed()
			.subscribe((answer) => {
				if (answer) {
					this.userService
						.disableMFA(user.id)
						.pipe(first())
						.subscribe((response) => {
							if (response.status === 204) {
								this.toastr.success(this.translate.instant("users.user.settings.2fa.disable.success"));
								user.totp_enabled = false;
							} else {
								this.toastr.error(this.translate.instant("core.message.error.unknown"));
							}
						});
				} else {
					this.toastr.info(this.translate.instant("core.message.nomodification"));
				}
			});
	}

	protected readonly EFilterMode = EFilterMode;

	transferUser(): void {
		if (this.userService.getDepartmentsIdAdmin().length === 0) {
			this.toastr.error(this.translate.instant("users.user.transfer.error.admin"));
			return;
		}
		this.dialog
			.open(TransferUserComponent, {
				width: "600px",
				disableClose: true,
			})
			.afterClosed()
			.subscribe((user) => {
				if (user) {
					this.users_all.push(user);
					this.filterUsers();
				}
			});
	}
}
