import { Component } from "@angular/core";
import { MatStepper, MatStep } from "@angular/material/stepper";
import { UserService } from "../../../services/user.service";
import { first } from "rxjs";
import { ToastrService } from "ngx-toastr";
import {
	MatDialogRef,
	MatDialogTitle,
	MatDialogContent,
	MatDialogActions,
	MatDialogClose,
} from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { NgFor, NgIf } from "@angular/common";
import { CdkTrapFocus } from "@angular/cdk/a11y";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxMaskDirective } from "ngx-mask";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { QRCodeModule } from "angularx-qrcode";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";

@Component({
	selector: "app-two-fa",
	templateUrl: "./two-fa.component.html",
	styleUrls: ["./two-fa.component.scss"],
	imports: [
		MatDialogTitle,
		MatDialogContent,
		MatStepper,
		MatStep,
		QRCodeModule,
		MatFormField,
		MatLabel,
		MatInput,
		NgxMaskDirective,
		ReactiveFormsModule,
		CdkTrapFocus,
		FormsModule,
		NgFor,
		MatCheckbox,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class TwoFAComponent {
	confirmBackupCodes = false;
	qrContent = "";
	isLoading = false;
	codeValidated = false;
	mfaCode = "";
	backupCodes = [];

	constructor(
		private userService: UserService,
		private toastr: ToastrService,
		private translate: TranslateService,
		private dialogRef: MatDialogRef<TwoFAComponent>
	) {
		this.isLoading = true;
		this.userService
			.getMFAQrCode()
			.pipe(first())
			.subscribe({
				next: (data) => {
					this.qrContent = data;
					this.isLoading = false;
				},
				error: () => {
					this.isLoading = false;
				},
			});
	}

	requestNextStep(stepper: MatStepper): void {
		if (this.mfaCode.trim() === "") {
			this.toastr.error(this.translate.instant("users.user.settings.2fa.code.request"));
			return;
		}
		if (stepper.selectedIndex === 0) {
			this.isLoading = true;
			this.userService
				.checkMFA(this.mfaCode)
				.pipe(first())
				.subscribe({
					next: (result) => {
						this.codeValidated = result.result;
						if (this.codeValidated) {
							this.getBackupCodes();
							setTimeout(() => {
								stepper.next();
							}, 100);
						}
						this.isLoading = false;
					},
					error: () => {
						this.isLoading = false;
						this.toastr.error(this.translate.instant("users.user.settings.2fa.code.invalid"));
					},
				});
		} else {
			stepper.next();
		}
	}

	getBackupCodes(): void {
		this.userService
			.getMFABackupCodes()
			.pipe(first())
			.subscribe((result) => {
				this.backupCodes = result.codes;
			});
	}

	enable2FA(): void {
		this.isLoading = true;
		this.userService
			.enableMFA()
			.pipe(first())
			.subscribe({
				next: () => {
					this.toastr.success(this.translate.instant("users.user.settings.2fa.enable.success"));
					this.isLoading = false;
					this.dialogRef.close(true);
				},
				error: () => {
					this.toastr.error(this.translate.instant("core.message.error.unknown"));
					this.isLoading = false;
				},
			});
	}
}
