import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Package } from "../interfaces/package";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { StripeSession } from "../interfaces/stripe-session";
import { Subscription } from "../interfaces/subscription";
import { MITPService } from "./mitp.service";

@Injectable({
	providedIn: "root",
})
export class SubscriptionService {
	constructor(
		private httpClient: HttpClient,
		private mitp: MITPService
	) {}

	getPackages(): Observable<Package[]> {
		return this.httpClient.get<Package[]>(this.mitp.Packages());
	}

	getEnabledPackages(): Observable<Package[]> {
		return this.httpClient.get<Package[]>(this.mitp.Packages() + "/enabled");
	}

	enablePackage(id: number, value: boolean) {
		if (value) {
			return this.httpClient.put(`${this.mitp.Packages()}/${id}/enable`, null, { observe: "response" });
		} else {
			return this.httpClient.put(`${this.mitp.Packages()}/${id}/disable`, null, { observe: "response" });
		}
	}

	createPackage(pack: Package): Observable<HttpResponse<Package>> {
		return this.httpClient.post<Package>(this.mitp.Packages(), pack, {
			observe: "response",
		});
	}

	editPackage(pack: Package): Observable<Package> {
		return this.httpClient.put<Package>(`${this.mitp.Packages()}/${pack.id}`, pack);
	}

	////////////////////  Section Subscription  \\\\\\\\\\\\\\\\\\\\

	getPortalUrl() {
		return this.httpClient.get(this.mitp.Subscriptions() + "/portal");
	}

	createStripeSession(code: number) {
		return this.httpClient.post<StripeSession>(this.mitp.Subscriptions() + `/packages/${code}`, null);
	}

	getActiveSubscription(): Observable<Subscription> {
		return this.httpClient.get<Subscription>(this.mitp.Subscriptions() + "/my");
	}

	notifyExpiredSubscription(userId: number) {
		return this.httpClient.get(this.mitp.Users() + `/${userId}/subscriptions/expired`, { observe: "response" });
	}
}
