import { Component, OnInit } from "@angular/core";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { User } from "../../../interfaces/users/user";
import { OrderByPipe } from "ngx-pipes";
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";
import * as fuzz from "fuzzball";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatIcon } from "@angular/material/icon";
import { MatOption } from "@angular/material/core";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { UserService } from "../../../services/user.service";
import { ProtocolService } from "../../../services/protocol.service";

@Component({
	selector: "app-transfer-managers",
	templateUrl: "./transfer-managers.component.html",
	styleUrls: ["./transfer-managers.component.scss"],
	imports: [
		MatDialogContent,
		MatFormField,
		MatLabel,
		MatInput,
		ReactiveFormsModule,
		MatAutocompleteTrigger,
		MatAutocomplete,
		NgFor,
		MatOption,
		MatIcon,
		MatSuffix,
		MatCheckbox,
		FormsModule,
		MatDialogActions,
		MatButton,
		MatDialogClose,
		NgIf,
		MatProgressSpinner,
		AsyncPipe,
		NgxTolgeeModule,
	],
})
export class TransferManagersComponent implements OnInit {
	removeCurrentManager: boolean = false;
	users_all: User[];
	isLoading: boolean = false;
	m1Control = new UntypedFormControl();
	m2Control = new UntypedFormControl();
	filteredUsers: Observable<User[]>;
	filteredUsers2: Observable<User[]>;
	private minLength: number = 3;

	constructor(
		private dialogRef: MatDialogRef<TransferManagersComponent>,
		private protocolService: ProtocolService,
		private userService: UserService,
		private toastr: ToastrService,
		private oPipe: OrderByPipe
	) {}

	ngOnInit(): void {
		this.userService.getUsers().subscribe((users) => {
			this.users_all = users;
			this.filteredUsers = this.m1Control.valueChanges.pipe(
				startWith(""),
				map((value: string | null) => (value.length > this.minLength ? this._filter(value) : []))
			);
			this.filteredUsers2 = this.m2Control.valueChanges.pipe(
				startWith(""),
				map((value: string | null) => (value.length > this.minLength ? this._filter(value) : []))
			);
		});
	}

	apply() {
		if (typeof this.m1Control.value == "string" || typeof this.m2Control.value == "string") {
			this.toastr.error("Vous devez sélectionner des gestionnaires depuis la liste proposée !");
			return;
		}
		if (this.m1Control.value?.id == this.m2Control.value?.id) {
			this.toastr.error("Vous devez sélectionner deux gestionnaires différents !");
			return;
		}
		this.isLoading = true;
		this.protocolService
			.transferManager(this.m1Control.value.id, this.m2Control.value.id, this.removeCurrentManager)
			.subscribe({
				next: (response) => {
					this.isLoading = false;
					if (response.status == 204) {
						this.toastr.success("Protocoles transférés avec succès.");
						this.dialogRef.close();
					} else {
						this.toastr.error("Une erreur inconnue est survenue !");
					}
				},
				error: () => {
					this.isLoading = false;
				},
			});
	}

	displayFn(user: User): string {
		return user && `${user.firstname} ${user.lastname}` ? `${user.firstname} ${user.lastname}` : "";
	}

	private _filter(value: any): User[] {
		if (typeof value === "string") {
			const filterValue = value.trim().toLowerCase();
			const resultUsers = this.users_all.filter((it) => {
				return (
					fuzz.partial_ratio(`${it.firstname} ${it.lastname}`.toLowerCase(), filterValue) > 60 ||
					fuzz.partial_ratio(`${it.lastname} ${it.firstname}`.toLowerCase(), filterValue) > 60
				);
			});
			return this.oPipe.transform(resultUsers, ["firstname", "lastname"]);
		}
	}
}
