import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { DepartmentService } from "../../../services/department.service";

@Component({
	selector: "app-create-department",
	templateUrl: "./create-department.component.html",
	styleUrls: ["./create-department.component.scss"],
	imports: [
		MatDialogModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInput,
		FormsModule,
		NgIf,
		MatProgressSpinner,
		NgxTolgeeModule,
	],
})
export class CreateDepartmentComponent {
	label: string = "";
	isLoading: boolean = false;

	constructor(
		private departmentService: DepartmentService,
		private toastr: ToastrService,
		private dialogRef: MatDialogRef<CreateDepartmentComponent>,
		private translateService: TranslateService
	) {}

	apply() {
		this.isLoading = true;
		this.departmentService.createDepartment(this.label).subscribe({
			next: (department) => {
				this.toastr.success(this.translateService.instant("department.create.success"));
				this.dialogRef.close(department);
			},
			error: () => {
				this.isLoading = false;
			},
		});
	}
}
